<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="9"
          class="mb-md-0 mb-2"
        >
          <label>No Register</label>
           <b-form-input
              id="no_register"
              autofocus
              trim
              placeholder="Pilih no register"
              :disabled="true"
              v-model="localData.no_register"
            />
            <small class="mt-1" style="color: red;" v-if="error.no_register">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2 mt-2"
        >
            <b-button class="btn" variant="primary" v-b-modal.jadwal_pemeriksaan_pilih_laporan>Pilih</b-button>
        </b-col>
         <b-modal :id="'jadwal_pemeriksaan_pilih_laporan'" size="xl" title="Laporan" ref="btnClose" hide-footer>
            <tpl-monitoring
              :is_pilihan="true"
              @getDataLaporan="getDataLaporan"
            />
            </b-modal>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Tenaga Ahli</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="val => val.value"
              :options="tenagaAhliOptions"
              v-model="localData.tenaga_ahli_id"
            />
            <small class="mt-1" style="color: red;" v-if="error.tenaga_ahli">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Struktural</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="val => val.value"
              :options="strukturalOptions"
              v-model="localData.struktural_id"
            />
            <small class="mt-1" style="color: red;" v-if="error.Struktural">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Tanggal</label>
            <b-form-datepicker id="tanggal" locale="id" v-model="localData.tanggal"></b-form-datepicker>
            <small class="mt-1" style="color: red;" v-if="error.tanggal">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Tempat</label>
            <b-form-input
              id="hari"
              autofocus
              trim
              placeholder="Tempat"
              v-model="localData.tempat"
            />
            <small class="mt-1" style="color: red;" v-if="error.tempat">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Waktu</label>
             <b-form-timepicker locale="id" v-model="localData.waktu"></b-form-timepicker>
            <small class="mt-1" style="color: red;" v-if="error.waktu">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Agenda</label>
             <b-form-textarea rows="3" v-model="localData.agenda"></b-form-textarea>
            <small class="mt-1" style="color: red;" v-if="error.agenda">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <b-button class="btn" variant="primary" @click="tambahData()">Simpan</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker, BFormTimepicker, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
    tambahDataJadwalPemeriksaan,
} from '@/connection-api/master'
import TplMonitoring from '@/views/tpl-monitoring/list/List.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    TplMonitoring,
    BModal,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
        tenaga_ahli_id: null,
        struktural_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    tenagaAhliOptions: {},
    strukturalOptions: {},
  },
  methods: {
    getDataLaporan(id,no_register)
    {
      this.localData.laporan_id = id
      this.localData.no_register = no_register
    },
    async uploadLogo(id)
    {
      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async tambahData()
    {
      const response = await tambahDataJadwalPemeriksaan(this.localData)
      if(response.data.success === true)
      {
        this.uploadLogo(response.data.id)
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Jadwal Pemeriksaan',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$root.$emit('bv::hide::modal', 'buat_jadwal_pemeriksaan')
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Jadwal Pemeriksaan',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
